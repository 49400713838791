import React from "react";
import { Box, Image} from "@chakra-ui/react";
import logo from '../../assets/themate.png'

import {useHistory} from 'react-router-dom'

export default function Logo({image, ...props}:any) {
  const history = useHistory()

  function backToHome() {
    history.push('/')
  }
  return (
    <Box {...props}>
      <Image cursor="pointer" w="120%" onClick={backToHome} src={logo} alt="OCR"/>
    </Box>
  );
}
