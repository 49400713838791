import React, {useState, useEffect} from 'react'
import { Text, Center, Input, VStack, Link,  HStack, InputGroup, Heading, Button, PinInput, PinInputField, Alert,
    AlertIcon,} from '@chakra-ui/react'

import Axios from 'axios'
import CopyButton from '../../utils/CopyButton'

import { linkUsed } from '../../utils/Link'
import { vgLink } from '../../utils/vgLink'

export default function OTP({shortcode,data, register, message, setIsCorrect, isCorrect, languageData, CloseModal}:any) {
    const [OTP, setOTP] = useState('')
    const [inputOTP, setInputOTP] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [afterWaiting, setAfterWaiting] = useState(false)
    const [resendCounter, setResendCounter] = useState(0)

    useEffect(() => {
        if (data && data.phone) {
            getOTP()
        }
        timer()
    }, [data])




    async function getOTP() {
        await Axios.get(`${linkUsed()}/verifyy-otp`, {
            params: {
                ID: data.phone
            }
        }).catch((err:any) => {
            console.log(err)
        }).then((res:any) => {
            setOTP(res.data.otp)
        })
    }


    function timer() {
        setTimeout(function(){ setAfterWaiting(true); }, 25000);
    }

    function timerClose() {
        setTimeout(function(){ CloseModal(); }, 5000);
    }

    function resend() {

        if (resendCounter < 4) {
                            if (data) {
            Axios.get(`${linkUsed()}/verifyy-otp`, {
                params: {
                    ID: data.phone
                }
            }).catch((err:any) => {
                console.log(err)
            }).then((res:any) => {
                setOTP(res.data.otp)
            })
        }


        setAfterWaiting(false)
        timer()
        let counter = resendCounter + 1
        setResendCounter(counter)

        } else {
            setAfterWaiting(false)
            setErrorMessage(languageData.misc.otp)
        }

    }

    // If the backend changed their message, this one needs to reflect, or you can built it some other way.
    function RegisterClipboard() {
        if (message.includes('already registered') || message.includes('OCR sales agent will be contacting') || message.includes('an agent for virtual tour viewing')) {
            return null;
        } else {
            return (<HStack w="50%" py="5">
            <Input borderColor="black" value={`${vgLink()}/f/${data.referral}.htm?code=${shortcode}`} isReadOnly={true} />
            <CopyButton code={`${vgLink()}/f/${data.referral}.htm?code=${shortcode}`} />
            </HStack>)
        }
    }

    function GoToVT() {
        if (message.includes('already registered') || message.includes('OCR sales agent will be contacting') || message.includes('an agent for virtual tour viewing')) {
            return null;
        } else {
            return (<Link isExternal href={`${vgLink()}/f/${data.referral}.htm?code=${shortcode}`}>
            <Button _hover={{background: '#3D4962', color:"white"}} m="5" background="#3D4962" color="white">Go To Virtual Tour</Button>
            </Link>)
        }
    }

    function verifyOTP(e:any) {
        e.preventDefault();

        if (inputOTP.length == 4) {
            if (inputOTP == OTP) {
                register();   
                setIsCorrect(true);
            } else {
                setErrorMessage('Incorrect OTP')
            }

        } else {
            setErrorMessage('Incorrect OTP')
        }  
    }
    return (
        <Center p="20" flexDir="column" d='flex' textAlign="center" justifyContent="center" alignItems="center" >

            {isCorrect ? 
            <>
             <Heading as="h2" >
                 {message}        
            </Heading>

                <RegisterClipboard/>

                <GoToVT/>

                
            
            <Text>
            
            </Text>
            </>
            
            : 
            
            <>
                {errorMessage.length !== 0 ? <Alert status="error">
                <AlertIcon />
                {errorMessage}
            </Alert>  : null}
            <Heading py="5" as="h2" >
                {languageData.misc.otp3}
            </Heading>
            <Text pt="5"> {languageData.misc.otp2}</Text>

            <InputGroup py="20" >
            <VStack w="100%">
                    <HStack mb="20">
        <PinInput otp onChange={(otp:any) => {setInputOTP(otp)}} >
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
        </PinInput>
        </HStack>
            <Button onClick={verifyOTP} _hover={{ bg: '#FC8181', color:"white"}} color="white" bg="#3D4962">Send</Button>
            {afterWaiting ? <Text cursor="pointer" onClick={resend}><u>{languageData.misc.otpResend}</u></Text> : null}
            </VStack>
            </InputGroup>
        
        
        </>
            
            
            }


            
        </Center>
    )
}
