export function cn() {

  let cn = {
    intro: "欢迎来到国齐轩@双子塔KLCC 虚拟之旅",
      createAcc: "注册新账号",
      login: "登录",
      createAcc2: "立即注册",
      register: "注册",
      usp: {
        one: "永久地契服务式公寓",
        two: "140个独家单位",
        three: "距离 KLCC 400米",
        four: "全自动化停车场系统"
      },
      privacy: "我本人同意，王将集团，旗下子公司以及合作伙伴（以下简称王将集团）可以收集、使用、披露和处理我的个人信息，并同意王将集团在适用情况下，收集我的个人数据用于以下用途：处理咨询、交易或使用任何服务、产品和设施；　市场营销及宣传活动；　对您在使用我们的产品和服务期间的行为进行监测和分析；　其他与我们服务、产品和设施体验相关的合理目的；　遵循法律法规的义务或审计用.",
      agree: "我同意王将集团的条款和隐私政策",
      verify: "账号认证",
      terms: "同意条款",
      agreed: "同意",
      selectCountry: "国家",
      cancelAgree: "取消",
      termsandCondition: "条款和条件",
      registered: "您现在已经注册",
      registerPage: {
        setUp: "请设置并核实您的页面，以便能体验我们的虚拟之旅",
        fullName: "真实性名",
        mobile: "手机号码",
        nationality: "国籍",
        email: "电子邮件",
        password: "密码",
        confirmPW: "确认密码",
        preferredAppoint: "首选预约日期和时间",
      },
      errors: {
        tnc: "条款和条件必须被同意。",
        nationality: "请选择国家。",
        appoint: "请选择首选预约日期和时间。",
        password: "请设置密码。",
        passwordMismatch: "密码不一致。",
        email: "请设置电子邮件。",
        email2: "请输入有效的电子邮件。",
        name: "请输入真实姓名。"
      },
      misc: {
        registered: "您已通过验证。王将集团的销售代理将会依照您的首先预约日期和时间与您联系。",
        otp: '重发请求过多，请稍后再试。',
        otp2: "一次性代码已发送到您的手机，请在输入字段中输入四位数的代码以进行验证",
        otp3: "请求一次性代码",
        otpResend: "点击这里重新发送",
        otpCode: "验证码"
      }
  }


return cn
    
}
