import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Text, Button } from "@chakra-ui/react";
import Logo from "../ui/Logo";

const MenuItem = ({ children, isLast, to = "/", ...rest }:any) => {
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      display="block"
      {...rest}
    >
      <Link to={to}>{children}</Link>
    </Text>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const Header = (props:any) => {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      h={["20vh","20vh","15vh","15vh"]}
      zIndex="1"
      px="8"
      bg={'transparent'}
      color={'white'}
      {...props}
    >
      <Flex align="center">
        <Logo
          w="180px"
        />
      </Flex>

      {/* <Box display={{ base: "block", md: "none" }} onClick={toggleMenu}>
        {show ? <CloseIcon /> : <MenuIcon />}
      </Box> */}

      <Box
        display={{ base: show ? "block" : "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
      >
        <Flex
          align="center"
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
        >
          {/* <MenuItem to="/how">How It works </MenuItem> */}
          {/* <MenuItem to="/login">Login</MenuItem> */}
          {/* <MenuItem to="/register" isLast>
            <Button
              size="sm"
              rounded="md"
              color={["white", "white", "white", "white"]}
              bg="linear-gradient(179.95deg, #C4C4C4 0.05%, rgba(0, 0, 0, 0.4) 99.95%)"
              _hover={{
                bg: "linear-gradient(179.95deg, #C4C4C4 0.05%, rgba(0, 0, 0, 0.4) 99.95%)"
              }}
            >
              Create Account
            </Button>
          </MenuItem> */}
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
