import React, {useReducer, useEffect, useState} from 'react'
import {
    Button,
    Text,
    Center,
    Flex,
    Stack,
    Alert,
    AlertIcon,
    Box,
    Image,
    Divider
} from "@chakra-ui/react"
import {reducer} from '../../reducers/reducer'
import Axios from 'axios'
import * as yup from 'yup';

import RegisterInput from './RegisterInput'
import LOGO from '../../assets/themate.png'
import { linkUsed } from '../../utils/Link';
import { vgLink } from '../../utils/vgLink';


export default function AgentLogin({languageData}:any) {
    const [{data}, dispatch] = useReducer(reducer, {data: []})
    const [mismatch, setMismatch] = useState(false)
    const [agreedTerms, setAgreedTerms] = useState(false)
    const [message, setMessage] = useState('')
    const [sent, setSent] = useState(false)
    const [nationality, setNationality] = useState('')
    const [selectedDate, setSelectedDate] = useState<any>('')
    const [isChinese, setIsChinese] = useState<boolean>(false)
    const [verifying, setVerifying] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [isCorrect, setIsCorrect] = useState(false);
    const [wechat, setWechatID] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [refID, setRefData] = useState('test')

    async function receiveMessageFromIndex ( event:any ) {
        console.log(await event.data)
        if (event.data.length == 4) {
            console.log(await event.data)
          setRefData(await event.data)
        }
      }
      
      useEffect(() => {
        function waitForIt() {
          
          return window.addEventListener("message", receiveMessageFromIndex, false);
        }
      
        waitForIt();
      
        async function getData() {
          await Axios.post(`${linkUsed()}/getreferrer`, {code: refID}).then((data) => setRefData(data.data))
        }
        if (refID) {
          getData()
        }
      }, [refID])
      
        
      function CloseModal() {
          let info = {
              close: true,
              origin: `${vgLink()}/login`
          }
        window.parent.postMessage(info, "*")
      }



    let schema = yup.object().shape({
        email: yup.string().email().required(languageData.errors.email),
        password: yup.string().min(8).required(languageData.errors.password),
        referral: yup.string().nullable(),
    })

    let schemaCN = yup.object().shape({
        email: yup.string().email().required(languageData.errors.email),
        password: yup.string().min(8).required(languageData.errors.password),
        referral: yup.string().nullable(),
    })

    async function verify() {
       let agentEmail = email;
       let agentPassword = password
            await Axios.post(`${linkUsed()}/login-agent`, {agentEmail, agentPassword}).then((response) => {
                  console.log(response.data)
                if (response.data.auth) {
                  localStorage.setItem("token",response.data.token)
                  CloseModal()
                } else {
                    setErrorMessage(response.data.message)
                }
              })
    }

    async function register() {

        if (!isChinese) {
            await schema.validate({
                email: data.email,
                password: data.password,
                referral: data.referral
                // referral: refID
            }).catch((err)=> {
                console.log(err.errors[0])
            }).then(async (validatedData) => {
                await Axios
                .post(`${linkUsed()}/register`, validatedData)
                .then((res:any) => {
                    if (res.data.error) {
                        setMessage(res.data.message)             
                    } else {
                        setMessage(res.data.message)
    
                    }
                })
            })
    
        } else {
            await schemaCN.validate({
                email: data.email,
                password: data.password,
                referral: data.referral
                // referral: refID
            }).catch((err)=> {
                console.log(err.errors[0])
            }).then(async (validatedData) => {
                await Axios
                .post(`${linkUsed()}/register`, validatedData)
                .then((res:any) => {
                    setSent(true);
                    if (res.data.error) {
                        setMessage(res.data.message)             
                    } else {
                        setMessage(res.data.message)
    
                    }
                })
            })
    
        }



    }




    useEffect(() => {

        if (!isChinese) {
            schema.isValid({
                email: data.email,
                password: data.password,
                referral: data.referral
            })
            .then((valid) => {
                if (!mismatch && valid) {
                    console.log('completed')
                }
                if (mismatch || !valid) {
                    console.log('incomplete')
                }
            })
        } else {
            schemaCN.isValid({
                email: data.email,
                password: data.password,
                referral: data.referral
            })
            .then((valid) => {
                if (!mismatch && valid) {
                    console.log('completed')
                }
                if (mismatch || !valid) {
                    console.log('incomplete')
                }
            })
        }


      }, [data, mismatch, agreedTerms, nationality])


       return (
           <Center  bg="rgba(255, 255, 255, 0.90)" boxShadow="xl"  borderRadius="md" h="70%" w={["100%","100%","100%","50%"]}>   
        <Stack  justifyContent="center" alignItems="center" h="100%" px="5%" py="15%" w="100%" color="#2D4D6E">
              {errorMessage.length !== 0 ? <Alert status="error">
                <AlertIcon />
                {errorMessage}
            </Alert>  : null}

            <Image pb="10" maxW="320px" src={LOGO} alt="logo" />

            <Text fontSize="2rem" fontWeight="medium" as={"h1"}>Agent Login</Text>
                <Text textAlign="center"  w="100%">Welcome to OCR: The Mate Virtual Tour</Text>
                <RegisterInput type={'email'} dispatch={(e:any) => setEmail(e.target.value)} label="Email" />
                <RegisterInput type={'password'} dispatch={(e:any) => setPassword(e.target.value)} label="Password" />


                <Box borderBottomStyle="solid"  py="2" w="100%" h="10px"></Box>
                <Divider/>



            <Flex  justifyContent={["center", "center", "left", "left"]}><Button w="250px" onClick={verify} _hover={{ bg: '#FC8181', color:"white"}} bg="#3D4962" color="white">Login</Button></Flex>
        </Stack>

        </Center>
    )
}
